<ng-container *ngIf="vm$ | async as vm; else spinner">
    <div [ngSwitch]="side">
        <div *ngSwitchDefault class="page-consent">
            <h1 class="mat-dialog-title">Du bestemmer over dine data</h1>
            <mat-dialog-content>
                <p>
                    Vi bruger nødvendige cookies til at få vores hjemmeside til at fungere. Vi vil derudover gerne bruge
                    valgfrie cookies
                    fra samarbejdspartnere til at optimere dit besøg på vores hjemmeside ved at sikre funktionalitet,
                    generere statistik og
                    huske dine præferencer samt til brug for markedsføring, så vi kan optimere vores reklametiltag på
                    andre hjemmesider og
                    sociale medier.
                </p>
                <p>
                    Vi placerer først de valgfrie cookies, hvis du har givet os dit samtykke nedenfor. Ved at klikke på
                    ”Tillad alle cookies” samtykker
                    du til de valgfrie cookies. Du kan til enhver tid trække dit samtykke tilbage eller ændre dit
                    samtykke ved at klikke på
                    cookie-ikonet nederst til venstre på hjemmesiden. Du skal være opmærksom på, at vores hjemmeside
                    muligvis ikke fungerer
                    optimalt, hvis du ikke accepterer cookies.
                </p>
            </mat-dialog-content>

            <mat-dialog-actions>
                <a class="read-more" href="#" (click)="onGotoReadMoreClick($event)">Læs mere...</a>
                <div>
                    <button mat-flat-button color="primary" [mat-dialog-close] (click)="onConsentApproveClick()"
                        data-cookiescanner="cookiepolicy-accept">
                        TILLAD ALLE COOKIES
                    </button>
                    <button mat-stroked-button color="primary" [mat-dialog-close] (click)="onConsentRejectClick()"
                        data-cookiescanner="cookiepolicy-reject">
                        KUN NØDVENDIGE COOKIES
                    </button>
                </div>
            </mat-dialog-actions>
        </div>
        <div *ngSwitchCase="'laes-mere'" class="page-laes-mere">
            <h1 class="mat-dialog-title">
                <a href="#" class="back" aria-label="Tilbage" (click)="onGotoConsentClick($event)"><mat-icon
                        svgIcon="dff-cookie-consent-dialog.arrow-left"></mat-icon></a>
                <span>Information om cookies</span>
            </h1>
            <mat-dialog-content>
                <mat-tab-group class="button-toggle">
                    <mat-tab label="Cookiepolitik">
                        <div [innerHTML]="vm.html"></div>
                    </mat-tab>
                    <mat-tab label="Cookies">
                        <div>
                            <ng-container *ngIf="vm.cookies.mandatory">
                                <h3>Nødvendige cookies</h3>
                                <dff-cookie-consent-cookie-list
                                    [cookies]="vm.cookies.mandatory"></dff-cookie-consent-cookie-list>
                            </ng-container>
                            <ng-container *ngIf="vm.cookies.optional">
                                <h3>Valgfri cookies</h3>
                                <dff-cookie-consent-cookie-list
                                    [cookies]="vm.cookies.optional"></dff-cookie-consent-cookie-list>
                            </ng-container>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-dialog-content>
        </div>
    </div>
</ng-container>
<ng-template #spinner>
    <div class="spinner-container">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>