import { LogentriesEnvironment } from "@dffedb/util";
import { EforsyningEnvironment } from "@e-forsyning/common/environment";

export const environment: EforsyningEnvironment & LogentriesEnvironment = {
    logentries: {
        application: "Forsyning|WEB",
        token: "6f65d82b-e060-4648-82a3-06481aca2c38"
    },
    production: false
};
